import React from 'react';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { Col, Row } from '../Grid';
import * as T from '../Typography';
import * as S from './style';

const Modal = ({
  visible,
  closeOnOK = true,
  parentFunc,
  children,
  setModalOpen,
  type,
  title,
  text,
  loading,
  okLabel,
  cancelLabel,
  handleBack
}) => {
  const { colors } = useTheme();

  const maskStyle = { backgroundColor: colors.darkGray, opacity: '0.9' };

  const handleOk = action => {
    parentFunc && parentFunc(action);
    closeOnOK && setModalOpen(false);
  };

  const handleCancel = () => {
    handleBack && handleBack();
    setModalOpen(false);
  };

  if (type === 'confirm') {
    return (
      <S.Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        maskStyle={maskStyle}
        transitionName=""
        maskTransitionName=""
      >
        <Row mb={4}>
          <Col w={[4, 12, 12]}>
            <T.P weight={700} mb="2" smallMedium>
              {title || 'Are you sure?'}
            </T.P>
            <T.P>{text}</T.P>
          </Col>
        </Row>
        <Row mb={4}>
          <Col w={[4, 12, 12]}>{children}</Col>
        </Row>
        <Row>
          <Col w={[4, 12, 6]}>
            <Button
              type="tertiary"
              onClick={handleCancel}
              label={cancelLabel || 'Back'}
            />
          </Col>
          <Col w={[4, 12, 6]}>
            <Button
              type="primary"
              onClick={() => handleOk('confirm')}
              label={okLabel || 'Confirm'}
              loading={loading}
            />
          </Col>
        </Row>
      </S.Modal>
    );
  }

  if (type === 'success') {
    return (
      <S.Modal
        visible={visible}
        onCancel={handleCancel}
        footer={[]}
        maskStyle={maskStyle}
        transitionName=""
        maskTransitionName=""
      >
        <Row mb={4}>
          <Col w={[4, 12, 12]}>
            <T.P weight={700} mb="2" smallMedium width="100%">
              {title || 'Success'}
            </T.P>
            <T.P>{text}</T.P>
          </Col>
        </Row>
        <Row mb={4}>
          <Col w={[4, 12, 12]}>{children}</Col>
        </Row>
        <Row>
          <Col w={[4, 12, 6]}>
            <Button type="primary" onClick={handleCancel} label="Close" />
          </Col>
        </Row>
      </S.Modal>
    );
  }

  return (
    <S.Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
      maskStyle={maskStyle}
      transitionName=""
      maskTransitionName=""
    >
      {loading ? <p>Loading</p> : children}
    </S.Modal>
  );
};

export default Modal;
