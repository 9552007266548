import Layout from './../../Layouts';
import * as T from './../../common/Typography';
import { Row, Col } from '../../common/Grid';

import LoginForm from '../../common/LoginForm';

const Login = ({ header }) => {
  return (
    <Layout maxWidth={'800px'}>
      <T.H1 ml={2}>{header || 'Log in to your account'}</T.H1>
      <Row mt={7}>
        <Col w={[4, 12, 10]}>
          <LoginForm />
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
