import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../Layouts';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';

import * as R from '../../../constants/navigationRoutes';
import CompletedModule from './CompletedModule';

import * as S from './style';

import {
  completeModule,
  fetchModule,
  withdrawFromModule
} from '../../../actions/modulesActions';
import { surveyTypesNew } from '../../../constants';

const ModuleSteps = ({
  loading,
  module,
  fetchModuleAction,
  withdrawFromModule,
  completeModule,
  email,
  userId,
  withdrawFromModuleLoading
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showWithdrawSuccessModal, setShowWithdrawSuccessModal] = useState(
    false
  );
  const { id } = useParams();
  const history = useHistory();
  const [markedAsComplete, setMarkedAsComplete] = useState(false);

  const surveySubmitted = history.location.state?.surveySubmitted;
  const justRegistered = history.location.state?.justRegistered;
  const assessmentUnsuccessful = history.location.state?.assessmentUnsuccessful;

  useEffect(() => {
    fetchModuleAction({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hasPreCourseMaterials = module?.preCourseMaterials?.length > 0;

  const completedPostSurvey = module.sessions?.[0]?.responses?.find(r => {
    return (
      r.user.toString() === userId.toString() &&
      r.surveyType === surveyTypesNew.POST
    );
  });

  const markAsComplete = async ({ moduleId }) => {
    await completeModule({
      moduleId: module._id,
      data: {
        sessionId: module.sessions?.[0]?._id
      }
    });

    setShowModal(true);
  };

  const handleWithdraw = async () => {
    await withdrawFromModule({
      moduleId: module?._id,
      data: {
        sessionId: module.sessions?.[0]?._id
      }
    });
    setShowWithdrawModal(false);
    setShowWithdrawSuccessModal(true);
  };

  const shouldFillPostSurvey =
    !completedPostSurvey && module.showPostCourseEvaluation;

  if (module?.participantAttendance?.completedAt || markedAsComplete) {
    return (
      <CompletedModule
        module={module}
        email={email}
        showPostCourse={shouldFillPostSurvey}
      />
    );
  }

  if (loading) {
    return (
      <Layout>
        <Spin />
      </Layout>
    );
  }

  return (
    <Layout>
      <S.Wrapper>
        <T.H1 weight="700" mb={3}>
          {assessmentUnsuccessful === true ? 'Unsuccessful' : module?.title}
        </T.H1>
        <T.Link
          external
          underline={true}
          color="darkGray"
          to={R.MODULE_URL.replace(':id', id)}
        >
          <S.IconWrapper mb={7}>
            <Icon icon="openInNew" mr="1" color="darkGray" />
            <T.P weight={600} small color="darkGray">
              View module details
            </T.P>
          </S.IconWrapper>
        </T.Link>
        {justRegistered && (
          <T.P color="gray" mb={4}>
            Thanks for registering for this module.
          </T.P>
        )}
        {assessmentUnsuccessful === true && (
          <T.P color="gray" mb={4} weight="400">
            Sorry, you did not pass the assessment. Please review the module and
            then try again.
          </T.P>
        )}
        {surveySubmitted && (
          <T.P color="gray" mb={4}>
            Thanks for completing the pre-module evaluation.
          </T.P>
        )}
        <T.P color="gray" weight={600}>
          On this page you can find all the materials and links needed to
          complete this module
        </T.P>
        {hasPreCourseMaterials && (
          <>
            <T.H2 mb="2" mt="6" weight={600}>
              Step 1
            </T.H2>
            <T.P mb="2" color="gray">
              Please read these materials before you begin the module.
            </T.P>
            {module.preCourseMaterials.map(material => (
              <T.Link
                key={material.link}
                external
                to={material.link}
                color="darkGray"
                underline
                weight={600}
                d="block"
              >
                {material.link}
              </T.Link>
            ))}
          </>
        )}
        <T.H2 mt="6" weight={600}>
          Step {hasPreCourseMaterials ? '2' : '1'}
        </T.H2>
        <T.P mb="3" color="gray">
          This will open the link in a new tab on your browser.
        </T.P>
        <a href={module.link} target="_blank" rel="noreferrer">
          <Button label="Begin module" type="primary" width="200px" />
        </a>
        <T.H2 mt="6" weight={600}>
          Step {hasPreCourseMaterials ? '3' : '2'}
        </T.H2>
        {module.assessmentNeeded ? (
          <>
            <T.P mb="2" color="gray" mt="2" weight="400">
              To receive your certification, please complete the assessment.
            </T.P>
            <Button
              label="Begin assessment"
              type="secondary"
              width="200px"
              onClick={() =>
                history.push(
                  R.PARTICIPANT_ASSESSMENT.replace(':id', module._id)
                )
              }
            />
          </>
        ) : (
          <>
            <T.P mb="3" color="gray">
              You can now mark this module as complete
            </T.P>
            <Button
              label="Mark as complete"
              type="secondary"
              width="200px"
              onClick={markAsComplete}
            />
          </>
        )}
      </S.Wrapper>
      <S.WithdrawWrapper>
        <S.WithdrawButton
          label="Withdraw from module"
          type="quartenary"
          onClick={() => setShowWithdrawModal(true)}
        />
      </S.WithdrawWrapper>
      <Modal visible={showModal} setModalOpen={setShowModal}>
        <T.H4W color="darkGray" mb="2" mt="2">
          Module successfully completed
        </T.H4W>
        <T.P color="gray" mb="10">
          Congratulations, you have completed this module!
          <br />
          {shouldFillPostSurvey
            ? 'Click to fill the post evaluation form (it only takes 2 minutes!)'
            : `You can see all module materials and certificate${
                module.enableCertificate ? ' and certificate' : ''
              } from the My Course section.`}
        </T.P>

        <Button
          label={shouldFillPostSurvey ? 'Fill in Evaluation' : 'Continue'}
          type="primary"
          width="200px"
          onClick={() => {
            if (shouldFillPostSurvey) {
              history.push(
                R.SURVEY_QUESTIONS_URL.replace(
                  ':id',
                  module.sessions?.[0]?.shortId
                )
                  .replace(':type', surveyTypesNew.POST)
                  .replace(':step', 0)
              );
            } else {
              setMarkedAsComplete(true);
              setShowModal(false);
            }
          }}
        />
      </Modal>
      <Modal
        type="confirm"
        cancelLabel="Cancel"
        visible={showWithdrawModal}
        setModalOpen={setShowWithdrawModal}
        onCancel={() => setShowWithdrawModal(false)}
        closeOnOK={false}
        loading={withdrawFromModuleLoading}
        parentFunc={handleWithdraw}
        text="This will remove the module from your account."
      />
      <Modal
        type="success"
        visible={showWithdrawSuccessModal}
        setModalOpen={setShowWithdrawSuccessModal}
        text="You have successfully withdrawn from this module."
        handleBack={() => history.push(R.PARTICIPANT_DASHBOARD)}
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  archivedModules: state.modules.modules,
  module: state.modules.module,
  loading: state.loading.fetchModuleLoading,
  completeLoading: state.loading.completeModuleLoading,
  email: state.auth.email,
  userId: state.auth.userId,
  withdrawFromModuleLoading: state.loading.withdrawFromModuleLoading
});

export default connect(mapStateToProps, {
  fetchModuleAction: fetchModule,
  completeModule,
  withdrawFromModule
})(ModuleSteps);
