const updateAnswersHeadersArray = ({
  questionText,
  dynamicHeadersArray,
  order,
  groupOrder,
  programme
}) => {
  const questionKey = { text: questionText, order, groupOrder, programme };
  if (!dynamicHeadersArray.some(header => header.text === questionText)) {
    dynamicHeadersArray.push(questionKey);
    dynamicHeadersArray.sort((a, b) => {
      if (a.groupOrder === b.groupOrder) {
        return a.order - b.order;
      }
      return a.groupOrder - b.groupOrder;
    });
  }
};

const addAnswerToResponse = ({ responseObj, questionText, answer }) => {
  let _answer = answer;
  if (Array.isArray(_answer)) {
    _answer = _answer.join(', ');
  } else if (typeof _answer === 'object') {
    let str = '';
    Object.entries(_answer).forEach(([key, value]) => {
      str += `${key}: ${value}，`;
    });
    _answer = str;
  }
  const questionKey = questionText;

  if (typeof _answer === 'string') {
    return (responseObj[questionKey] = _answer?.replace(/,/g, '，'))?.replace(
      /"/g,
      '”'
    );
  }
  return (responseObj[questionKey] = _answer);
};

const transformer = ({ response, headers }) => {
  const responseObj = { ...response };

  if (headers.fixedHeaders.length === 0) {
    const keys = Object.keys(responseObj).filter(key => key !== 'answers');
    headers.fixedHeaders = [...new Set([...headers.fixedHeaders, ...keys])];
  } else {
    Object.keys(responseObj).forEach(key => {
      if (!headers.fixedHeaders.includes(key) && key !== 'answers') {
        headers.fixedHeaders = [...new Set([...headers.fixedHeaders, key])];
      }
    });
  }

  if (response.answers && response.answers.length > 0) {
    response.answers.forEach(answer => {
      const { question, order, groupOrder, programme } = answer;
      updateAnswersHeadersArray({
        questionText: question,
        dynamicHeadersArray: headers.dynamicHeadersArray,
        order,
        groupOrder,
        programme
      });
      addAnswerToResponse({
        responseObj,
        questionText: question,
        answer: answer.answer
      });
    });
  }

  delete responseObj.answers;
  return responseObj;
};

const getCsvDataAndHeadersForExportResponses = data => {
  const headers = {
    fixedHeaders: [],
    dynamicHeadersArray: []
  };

  const csvDataWithAnswers = data.map(response =>
    transformer({
      response,
      headers
    })
  );

  const generalHeaders = headers.dynamicHeadersArray
    .filter(header => !header.programme)
    .map(header => header.text);

  const programmeHeaders = headers.dynamicHeadersArray
    .filter(header => header.programme)
    .reduce((acc, header) => {
      if (!acc[header.programme]) {
        acc[header.programme] = [];
      }
      acc[header.programme].push(header.text);
      return acc;
    }, {});

  const dynamicHeaders = [
    ...generalHeaders,
    ...Object.values(programmeHeaders).flat()
  ];

  return {
    csvData: csvDataWithAnswers,
    csvHeaders: [...headers.fixedHeaders, ...dynamicHeaders]
  };
};

export default getCsvDataAndHeadersForExportResponses;
