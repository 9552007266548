import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as T from '../../common/Typography';
import CheckRequirements from './CheckRequirements';

import {
  MODULE_STEPS_URL,
  PARTICIPANT_DASHBOARD,
  PARTICIPANT_LOGIN,
  PARTICIPANT_SESSION_DETAILS,
  SURVEY_QUESTIONS_URL
} from '../../../constants/navigationRoutes';

import NextAndBackButtons from './NextAndBackButtons';

import Spin from '../../common/Spin';

import { surveyTypesNew, userRoles } from '../../../constants';

// Styles
import Progress from '../../common/Progress';
import Layout from '../../Layouts';

import { Container, Divider, SpinDiv, SpinWrapper } from './Survey.style';

// Actions
import {
  fetchSurveyData,
  getParticipantByEmail,
  submitSurvey
} from '../../../actions/surveyAction';
// Components
import SurveyQs from './SurveyQs';

import {
  isDeprecatedSurveyType,
  mapSurveyTypeWithBackwardCompatibility
} from '../../../helpers';
import useSearchParams from '../../../hooks/useSearchParams';

const Spinner = () => (
  <SpinWrapper>
    <Spin size="large" />
  </SpinWrapper>
);

const Survey = ({
  id,
  step,
  surveyData,
  uniqueGroups,
  submitSurvey,
  type,
  success,
  submissionLoading
}) => {
  const searchParams = useSearchParams();

  const sectionTitle = uniqueGroups?.[step];
  const [questions, setQuestions] = useState([]);

  const history = useHistory();

  const visibleQuestions =
    questions?.filter(question => question.isVisible) || [];

  const currentQuestions = visibleQuestions.filter(
    question => question.group.text === sectionTitle
  );

  const isOnboarding =
    type === surveyTypesNew.ONBOARDING ||
    searchParams.params.onboarding === 'true' ||
    (searchParams.params.initialSignup === 'true' &&
      searchParams.params.registerAfterAuth === 'true');

  useEffect(() => {
    // If page opened with step !== 0, redirect to step 0
    let newStep = Number(step);
    let newType = type;
    let shouldReplace = false;

    if (newStep !== 0) {
      newStep = 0;
      shouldReplace = true;
    }

    if (newType && isDeprecatedSurveyType(newType)) {
      newType = mapSurveyTypeWithBackwardCompatibility(newType);
      shouldReplace = true;
    }

    if (shouldReplace) {
      history.push({
        pathname: SURVEY_QUESTIONS_URL.replace(':id', id)
          .replace(':step', newStep)
          .replace(':type', newType),
        search: history.location.search
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectAfterSurvey = (surveyData, isOnboarding, history) => {
    let pathname = isOnboarding
      ? `${PARTICIPANT_DASHBOARD}?initialSignup=true&onboardingCompleted=true`
      : PARTICIPANT_SESSION_DETAILS.replace(':id', surveyData.sessionId);
    if (surveyData.defaultSessionForCustomModule) {
      pathname = MODULE_STEPS_URL.replace(':id', surveyData.modules?.[0]?._id);
    }

    history.push(pathname);
  };

  useEffect(() => {
    // If there is survey data but the questions array is empty, redirect
    if (surveyData?.questionsForSurvey?.length === 0) {
      return handleRedirectAfterSurvey(surveyData, isOnboarding, history);
    }

    // Initially, all children questions are invisible
    setQuestions(
      surveyData?.questionsForSurvey.map(question => ({
        ...question,
        isVisible: question.metadata?.parentQuestion ? false : true
      })) || []
    );
  }, [history, isOnboarding, surveyData]);

  const setQuestion = useCallback(
    ({ _id, ...data }) => {
      const newQuestions =
        questions?.map(q => {
          if (q?._id?.toString() === _id?.toString()) {
            return { ...q, ...data };
          }
          return q;
        }) || [];
      setQuestions(newQuestions);
    },
    [questions]
  );

  const allCurrentGroupAnswered = currentQuestions
    .filter(question => question.isRequired)
    .every(question => question.answered);

  const answeredQuestionsCount = visibleQuestions.filter(
    question => question.answered && question.isRequired
  ).length;

  const requiredQuestionsCount = visibleQuestions.filter(
    question => question.isRequired
  ).length;

  const allQuestionsAnswered =
    answeredQuestionsCount === requiredQuestionsCount;

  const onSubmit = async () => {
    const answeredQuestions = visibleQuestions.filter(
      question => !!question.answer
    );

    await submitSurvey({
      sessionId: surveyData.sessionId,
      surveyType: type,
      questions: answeredQuestions.map(q => ({
        _id: q._id,
        answer: q.answer,
        answered: q.answered,
        otherAnswer: q.otherAnswer,
        participantField: q.participantField
      })),
      cb: () => {
        handleRedirectAfterSurvey(surveyData, isOnboarding, history);
      }
    });
    surveyData.questionsForSurvey = [];
  };

  useEffect(() => {
    questions.forEach(question => {
      const { metadata, isVisible } = question;
      if (metadata?.parentQuestion) {
        const parentQuestion = questions.find(
          question => question.participantField === metadata.parentQuestion.ref
        );
        if (
          !isVisible &&
          parentQuestion.answer === metadata.parentQuestion.showOnValue
        ) {
          setQuestion({
            ...question,
            isVisible: true
          });
        } else if (
          // Question was visible and now it's not
          isVisible &&
          parentQuestion.answer !== metadata.parentQuestion.showOnValue
        ) {
          setQuestion({
            ...question,
            isVisible: false
          });
        }
      }
    });
  }, [questions, setQuestion, allQuestionsAnswered]);

  return (
    <Layout>
      <CheckRequirements />
      <Container mb="100px">
        {isOnboarding && (
          <>
            <T.H1 color="darkGray" mb="4">
              Welcome to the Learning Academy!
            </T.H1>
            <Divider full />
            <T.P color="gray" mb="4">
              Thank you for registering onto the Learning Academy. To complete
              your sign up we need to ask a few questions about yourself. This
              will only take a couple of minutes.
            </T.P>
          </>
        )}
        {!isOnboarding && type === surveyTypesNew.PRE && (
          <>
            <T.H1 color="darkGray" mb="4">
              Evaluation
            </T.H1>
            <Divider full />
            <T.P color="gray" mb="4">
              To help monitor feedback and for you to track your progress as you
              participate in the Learning Academy, please answer the questions
              below. All answers will be anonymised, except for you to view in
              your personal account.
            </T.P>
          </>
        )}
        {type === surveyTypesNew.POST && (
          <>
            <T.H1 color="darkGray" mb="4">
              Evaluation
            </T.H1>
            <Divider full />
            <T.P color="gray" mb="4">
              Congratulations on your recent learning. To help us improve the
              programmes, we have a couple of questions for you below. All
              answers will be anonymised, except for you to view in your
              personal account. This will only take a couple of minutes.
            </T.P>
          </>
        )}
        {success === 'true' ? (
          <>
            <T.H1 color="darkGray" mb="7" weight="700">
              Success!
            </T.H1>
            <div style={{ maxWidth: '583px' }}>
              <T.P color="gray" mb="4" weight="400">
                Congratulations, you have successfully passed the assessment.
                Now complete this short evaluation and you will then receive
                your certification and post-module materials.
              </T.P>
            </div>
          </>
        ) : null}
        {visibleQuestions.length === 0 ? (
          <SpinDiv>
            <Spin size="large" />
          </SpinDiv>
        ) : (
          <SurveyQs
            key={sectionTitle}
            questions={currentQuestions}
            setQuestion={setQuestion}
            hideGroupHeading={
              (!isOnboarding && type === surveyTypesNew.PRE) ||
              type === surveyTypesNew.POST
            }
          />
        )}
        <NextAndBackButtons
          section={sectionTitle}
          uniqueGroups={uniqueGroups}
          step={step}
          allCurrentGroupAnswered={allCurrentGroupAnswered}
          allQuestionsAnswered={allQuestionsAnswered}
          onSubmit={onSubmit}
          loading={submissionLoading}
        />
        <Progress
          readyForSubmission={allQuestionsAnswered}
          completionRate={Math.round(
            (answeredQuestionsCount / requiredQuestionsCount) * 100
          )}
          currentStep={step}
          totalSteps={uniqueGroups.length}
        />
      </Container>
    </Layout>
  );
};

const SurveyWrapper = ({
  fetchSurveyData,
  loaded,
  surveyLoaded,
  user,
  role,
  ...props
}) => {
  const { id, type, step, success } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (loaded && !user?.userId) {
      return history.push({
        pathname: PARTICIPANT_LOGIN,
        search: `?source=survey&shortId=${id}&surveyType=${type}`
      });
    }
  }, [history, id, loaded, type, user]);

  useEffect(() => {
    fetchSurveyData({ id, type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    if (!step) {
      history.push({
        pathname: SURVEY_QUESTIONS_URL.replace(':id', id)
          .replace(':step', 0)
          .replace(':type', type),
        search: history.location.search
      });
    }
  }, [history, id, step, type]);

  if (!loaded || !surveyLoaded || role !== userRoles.participant) {
    return <Spinner />;
  }

  return (
    <Survey step={step} type={type} id={id} success={success} {...props} />
  );
};

const mapStateToProps = state => {
  return {
    surveyData: state.survey.surveyData,
    uniqueGroups: state.survey.uniqueGroups,
    surveyLoaded: state.survey.loaded,
    loaded: state.auth.loaded,
    submissionLoading: state.survey.submissionLoading,
    user: state.auth,
    role: state.auth.role
  };
};

export default connect(mapStateToProps, {
  fetchSurveyData,
  submitSurvey,
  getParticipantByEmail
})(SurveyWrapper);
