import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from '../Grid';
import Table from '../Table';
import * as T from '../Typography';

import ExportButton from '../ExportButton';
import FilterResults from '../FilterResults';

const surveysColumns = [
  {
    label: 'Type',
    value: 'title',
    align: 'left'
  },
  {
    label: '# of Responses',
    value: 'responses',
    align: 'center'
  },
  {
    label: 'Response Rate',
    value: 'responseRate',
    align: 'center'
  }
];

const EvaluationResponses = ({
  data,
  role,
  handleFilteredData,
  filters,
  fetchUserResultsLoading
}) => {
  const [responsesFilters, setResponsesFilters] = useState(filters);

  useEffect(() => {
    handleFilteredData({
      ...responsesFilters,
      ...filters,
      panel: 'evaluationResponses'
    });
  }, [filters, handleFilteredData, responsesFilters]);

  // check if any response rates are over 100% and if so give the user a message
  const over100 = useMemo(
    () =>
      data.newSurveys &&
      data.newSurveys.filter(survey => {
        const responseRate = survey.responseRate.split('%')[0];
        return responseRate > 100;
      }),
    [data.newSurveys]
  );

  useEffect(() => {
    if (over100?.length > 0) {
      message.warning(
        <>
          <h3 style={{ fontSize: '1rem' }}>
            You have surveys with a response rate over 100%.
          </h3>
          <p>
            This is because you have had more responses than confirmed
            attendees. To fix this please update your sessions to have the
            correct number of people who attended
          </p>
        </>,
        2
      );
    }
  }, [over100]);

  if (fetchUserResultsLoading) {
    return <T.P>Fetching data...</T.P>;
  }

  return (
    <div>
      <T.P mb="5">
        Export every evaluation response submitted for this programme.{' '}
      </T.P>
      <FilterResults
        role={role}
        applyFiltersCb={_filters => {
          setResponsesFilters(_filters);
        }}
      />
      <Table columns={surveysColumns} data={data.newSurveys} />
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...filters,
              surveyType: 'pre-course',
              ...responsesFilters
            }}
            type="primary"
            text="Export pre-course responses"
            height="50px"
            exportType="responses"
          />
        </Col>
      </Row>
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...filters,
              surveyType: 'post',
              ...responsesFilters
            }}
            type="tertiary"
            text="Export post responses"
            height="50px"
            exportType="responses"
          />
        </Col>
      </Row>
      <Row inner mt={4}>
        <Col w={[4, 6, 6]}>
          <ExportButton
            filters={{
              ...filters,
              surveyType: 'followup',
              ...responsesFilters
            }}
            type="primary"
            text="Export 3 & 6-month follow-ups"
            height="50px"
            exportType="responses"
          />
        </Col>
      </Row>
    </div>
  );
};

export default EvaluationResponses;
