import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { fetchProgrammes } from '../../../actions/programmesActions';
import {
  deleteUser,
  deleteUserInvitationDetailsByToken,
  fetchUser,
  requestUserPasswordReset,
  sendUserInvitation,
  updateUser,
  updateUserInvitation
} from '../../../actions/users';
import { MANAGE_USERS } from '../../../constants/navigationRoutes';
import USER_TYPES, { USER_TYPE_LABELS } from '../../../constants/userTypes';
import { checkArraysEqual } from '../../../helpers';
import useFormData from '../../../hooks/useFormData';
import useValidateFormData from '../../../hooks/useValidateFormData';
import { customSwal } from '../../../theme';
import { editUserValidation as validate } from '../../../validation/schemas';
import Layout from '../../Layouts';
import Button from '../../common/Button';
import CopyLink from '../../common/CopyLink';
import { Col, Row } from '../../common/Grid';
import * as I from '../../common/Inputs';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';
import Modal from '../../common/modal';
import * as S from './style';

const initialFormState = {
  name: '',
  email: '',
  roles: [],
  managedProgrammes: []
};

const UserAddEdit = ({
  fetchProgrammesLoading,
  programmes,
  fetchUserLoading,
  user,
  authUser,
  fetchProgrammesAction,
  fetchUserAction,
  updateUserAction,
  updateUserLoading,
  deleteUserAction,
  deleteUserLoading,
  requestUserPasswordResetAction,
  sendUserInvitationAction,
  sendUserInvitationLoading,
  userInvitation,
  deleteUserInvitationLoading,
  deleteUserInvitationDetailsByTokenAction,
  updateUserInvitationAction,
  updateUserInvitationLoading
}) => {
  const location = useLocation();
  const history = useHistory();

  const isEdit = useMemo(() => location.pathname.includes('/edit'), [location]);
  const isInvitedUser = useMemo(() => user?.invitationDetails, [
    user?.invitationDetails
  ]);

  const isParticipantUpgrade = useMemo(() => user?.participantUpgrade, [
    user?.participantUpgrade
  ]);

  const { userId } = useParams();

  const { formState, setFormData } = useFormData({ initialFormState });

  const submitAttempt = useRef(false);
  const upgradeModalDismissed = useRef(false);

  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen
  ] = useState(false);

  const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(
    false
  );

  const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(
    false
  );

  const [
    isUpdateAccessConfirmationModalOpen,
    setIsUpdateAccessConfirmationModalOpen
  ] = useState(false);

  const [
    isPasswordResetLinkModalOpen,
    setIsPasswordResetLinkModalOpen
  ] = useState(false);

  const [
    isInvitationSuccessModalOpen,
    setIsInvitationSuccessModalOpen
  ] = useState(false);

  const [
    isParticipantUpgradeInvitationSentModalOpen,
    setIsParticipantUpgradeInvitationSentModalOpen
  ] = useState(false);

  const {
    validateForm,
    validationErrors,
    finalSubmissionData
  } = useValidateFormData({
    formState,
    validateFn: validate,
    submitAttempt
  });

  const handleUpdateUser = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      upgradeModalDismissed.current = false;
      const { current: finalFormState } = finalSubmissionData;

      await updateUserAction(userId, finalFormState);

      setIsUpdateSuccessModalOpen(true);
    }
  }, [validateForm, finalSubmissionData, updateUserAction, userId]);

  const handleAddUser = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      const { current: finalFormState } = finalSubmissionData;

      await sendUserInvitationAction({
        fullName: finalFormState.name,
        email: finalFormState.email,
        roles: finalFormState.roles,
        programmes: finalFormState.managedProgrammes
      });

      setIsInvitationSuccessModalOpen(true);
    }
  }, [validateForm, finalSubmissionData, sendUserInvitationAction]);

  const handleUpdateUserInvitation = useCallback(async () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      const { current: finalFormState } = finalSubmissionData;

      await updateUserInvitationAction(user?.invitationDetails?.token, {
        fullName: finalFormState.name,
        email: finalFormState.email,
        roles: finalFormState.roles,
        programmes: finalFormState.managedProgrammes
      });

      setIsUpdateSuccessModalOpen(true);
      fetchUserAction(userId);
    }
  }, [
    fetchUserAction,
    finalSubmissionData,
    updateUserInvitationAction,
    user?.invitationDetails?.token,
    userId,
    validateForm
  ]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isEdit) {
        if (!checkArraysEqual(user?.roles, formState?.roles)) {
          setIsUpdateAccessConfirmationModalOpen(true);
        } else if (isInvitedUser) {
          handleUpdateUserInvitation();
        } else {
          handleUpdateUser();
        }
      } else {
        handleAddUser();
      }
    },
    [
      isEdit,
      user?.roles,
      formState?.roles,
      isInvitedUser,
      handleUpdateUserInvitation,
      handleUpdateUser,
      handleAddUser
    ]
  );

  const handleDeleteUser = useCallback(async () => {
    if (!isInvitedUser) {
      await deleteUserAction(userId);
      fetchUserAction(userId);
      setIsDeleteSuccessModalOpen(true);
    } else {
      await deleteUserInvitationDetailsByTokenAction(
        user?.invitationDetails?.token
      );
      setIsDeleteSuccessModalOpen(true);
    }
  }, [
    deleteUserAction,
    deleteUserInvitationDetailsByTokenAction,
    fetchUserAction,
    isInvitedUser,
    user?.invitationDetails?.token,
    userId
  ]);

  const handleResetPasswordRequest = useCallback(async () => {
    await requestUserPasswordResetAction(user?.email, null, true);
    setIsPasswordResetLinkModalOpen(true);
  }, [requestUserPasswordResetAction, user?.email]);

  const isDeleted = useMemo(() => user?.isDeleted, [user]);

  useEffect(() => {
    if (isEdit) {
      fetchUserAction(userId);
    }
  }, [isEdit, userId, fetchUserAction]);

  useEffect(() => {
    fetchProgrammesAction({ forAdmin: true });
  }, [fetchProgrammesAction]);

  useEffect(() => {
    if (isEdit && !!user) {
      setFormData({
        ...user,
        managedProgrammes: [
          ...new Set(
            user?.managedProgrammes?.map(
              programme => programme._id?.toString() || programme
            )
          )
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      !(
        formState.roles.includes(USER_TYPES.programmeManager) ||
        formState.roles.includes(USER_TYPES.trainer)
      ) &&
      !!formState.managedProgrammes?.length
    ) {
      setFormData({
        ...formState,
        managedProgrammes: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.roles, formState]);

  useEffect(() => {
    if (
      isParticipantUpgrade &&
      !isParticipantUpgradeInvitationSentModalOpen &&
      !upgradeModalDismissed.current
    ) {
      setIsParticipantUpgradeInvitationSentModalOpen(true);
      upgradeModalDismissed.current = true;
    }
  }, [isParticipantUpgrade, isParticipantUpgradeInvitationSentModalOpen, user]);

  if ((isEdit && !user) || fetchUserLoading || fetchProgrammesLoading) {
    return <Spin />;
  }

  const sortedProgrammes = programmes?.sort((a, b) =>
    a.titleShort.localeCompare(b.titleShort)
  );

  return (
    <Layout>
      <Row>
        <Col w={[4, 6, 6]}>
          <T.H2W>
            {isEdit ? user?.name : 'Add User'} {isDeleted && ' (Deleted User)'}
          </T.H2W>
        </Col>
        <Col w={[4, 6, 6]} jc="flex-end">
          {!isDeleted && isEdit && user?.userId !== authUser?.credentialId && (
            <Button
              label="Delete user"
              width="200px"
              height="auto"
              type="tertiary"
              loading={deleteUserLoading || deleteUserInvitationLoading}
              disabled={deleteUserLoading}
              onClick={() => {
                setIsDeleteConfirmationModalOpen(true);
              }}
            />
          )}
        </Col>
        <S.Divider full />
        {!isEdit && (
          <T.P mb={6} small>
            Here you can set up trainers, programme managers and other admin
            users.
          </T.P>
        )}
      </Row>
      {isEdit && (
        <>
          <Row>
            <Col w={[4, 8, 8]}>
              <S.UserCard>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Email</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>{!isDeleted ? user?.email : 'N/A'}</T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Password</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    {!isDeleted && !isInvitedUser ? (
                      <>
                        {' '}
                        <T.P weight={600}>*** *** ***</T.P>
                        <T.P
                          weight={600}
                          underline
                          ml={5}
                          onClick={handleResetPasswordRequest}
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          Reset Password
                        </T.P>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Organisation</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>{user?.organisation || 'N/A'}</T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Local authorities</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>
                      {!!user?.localAuthorities?.length
                        ? user?.localAuthorities.join(' & ')
                        : 'N/A'}
                    </T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Job role</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>{user?.jobRole || 'N/A'}</T.P>
                  </Col>
                </Row>
                <Row mb={6}>
                  <Col w={[4, 4, 4]}>
                    <T.P weight={600}>Roles</T.P>
                  </Col>
                  <Col w={[4, 8, 8]}>
                    <T.P>
                      {user?.roles
                        ?.map(role => USER_TYPE_LABELS[role])
                        ?.join(' & ')}
                    </T.P>
                  </Col>
                </Row>
              </S.UserCard>
            </Col>
            {isInvitedUser && (
              <Col w={[4, 4, 4]}>
                <S.AwaitingSignupCard>
                  <T.H4 mb={2}>Awaiting sign up</T.H4>
                  <T.P mb={2}>
                    This user has not yet created their account. Please copy and
                    send them their unique invite link below.
                  </T.P>
                  <CopyLink big link={user?.invitationDetails?.link} />
                </S.AwaitingSignupCard>
              </Col>
            )}
          </Row>
          <S.Divider full />
        </>
      )}
      <Row>
        <Col w={[4, 6, 6]}>
          <S.Form>
            {!isEdit && (
              <I.BasicInput
                label="Name"
                required
                placeholder="Type here..."
                mb="5"
                value={formState.name}
                handleChange={name => setFormData({ name })}
                error={validationErrors.name}
              />
            )}
            <I.BasicInput
              label="Email"
              required
              placeholder="Type here..."
              mb="5"
              value={!isDeleted ? formState.email : ''}
              handleChange={email => setFormData({ email })}
              disabled={isDeleted}
              error={validationErrors.email}
            />
            <I.Dropdown
              m={{
                mb: '5'
              }}
              label="Role(s)"
              required
              addNew={false}
              search={true}
              options={Object.entries(USER_TYPE_LABELS)
                .map(([key, value]) => ({
                  label: value.toLowerCase(),
                  value: key
                }))
                .filter(({ value }) => value !== USER_TYPES.participant)}
              multi
              selected={formState.roles}
              handleChange={roles => {
                if (
                  formState.roles.includes(USER_TYPES.participant) &&
                  !roles.includes(USER_TYPES.participant)
                ) {
                  return Swal.fire({
                    title: 'Error',
                    text: 'Participant role can not be removed!',
                    confirmButtonText: 'OK',
                    ...customSwal
                  });
                }

                if (
                  formState.roles.length === 1 &&
                  formState.roles[0] === USER_TYPES.participant &&
                  roles.length === 2 // Only on first role change
                ) {
                  return Swal.fire({
                    title: 'Warning',
                    text:
                      'This action will not have effect until the participant follows the link in the invitation email.',
                    confirmButtonText: 'OK',
                    showDenyButton: true,
                    denyButtonText: 'Cancel',
                    preConfirm: () => {
                      setFormData({ roles });
                    },
                    ...customSwal
                  });
                }

                setFormData({ roles });
              }}
              disabled={isDeleted}
              error={validationErrors.roles}
            />

            {(formState.roles.includes(USER_TYPES.programmeManager) ||
              formState.roles.includes(USER_TYPES.trainer)) && (
              <I.Dropdown
                m={{
                  mb: '5'
                }}
                label="Programme(s)"
                required
                addNew={false}
                search={true}
                options={sortedProgrammes?.map(programme => ({
                  label: programme.titleShort,
                  value: programme._id.toString()
                }))}
                multi
                selected={formState.managedProgrammes}
                handleChange={managedProgrammes =>
                  setFormData({ managedProgrammes })
                }
                disabled={isDeleted}
                error={validationErrors.managedProgrammes}
              />
            )}
            <Button
              label={isEdit ? 'Update' : 'Create'}
              type="primary"
              onClick={handleSubmit}
              loading={
                updateUserLoading ||
                sendUserInvitationLoading ||
                updateUserInvitationLoading
              }
              disabled={
                updateUserLoading || isDeleted || sendUserInvitationLoading
              }
            />
          </S.Form>
        </Col>
      </Row>
      <Modal
        type="confirm"
        visible={isDeleteConfirmationModalOpen}
        setModalOpen={setIsDeleteConfirmationModalOpen}
        text="You are about to delete this user. All personal identifiable information will be removed and they will no longer be able to log in. This cannot be undone."
        parentFunc={handleDeleteUser}
      />
      <Modal
        type="confirm"
        visible={isUpdateAccessConfirmationModalOpen}
        setModalOpen={setIsUpdateAccessConfirmationModalOpen}
        text="You are about to update this user's access level - this may prevent them from accessing certain information."
        parentFunc={
          !isInvitedUser ? handleUpdateUser : handleUpdateUserInvitation
        }
      />
      <Modal
        type="success"
        visible={isDeleteSuccessModalOpen}
        setModalOpen={setIsDeleteSuccessModalOpen}
        text="This user has been successfully deleted."
        handleBack={
          isInvitedUser
            ? () => {
                history.push(MANAGE_USERS);
              }
            : () => {}
        }
      />
      {!isParticipantUpgrade && (
        <Modal
          type="success"
          visible={isUpdateSuccessModalOpen}
          setModalOpen={setIsUpdateSuccessModalOpen}
          text="This user has been updated. Please notify them if required."
        />
      )}
      <Modal
        type="success"
        title="Reset password"
        visible={isPasswordResetLinkModalOpen}
        setModalOpen={setIsPasswordResetLinkModalOpen}
      >
        <CopyLink
          big
          title="Please copy the link below and send to the user. This link will be valid for 24 hours."
          link={authUser?.resetPasswordLink}
        />
      </Modal>
      <Modal
        type="success"
        title="Success"
        visible={isInvitationSuccessModalOpen}
        setModalOpen={setIsInvitationSuccessModalOpen}
        handleBack={() => {
          history.push(MANAGE_USERS);
        }}
      >
        <CopyLink
          big
          title="New user created. They have been emailed their invite link. You can also copy it below to send it to them manually."
          link={userInvitation?.link}
        />
      </Modal>
      <Modal
        type="success"
        title="Success"
        visible={isParticipantUpgradeInvitationSentModalOpen}
        setModalOpen={setIsParticipantUpgradeInvitationSentModalOpen}
      >
        <CopyLink
          big
          title="Participant upgrade invitation sent successfully! They have been emailed their invite link. You can also copy it below to send it to them manually."
          link={user.invitationLink}
        />
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  fetchProgrammesLoading: state.loading.fetchProgrammesLoading,
  programmes: state.programmes.programmes,
  fetchUserLoading: state.loading.fetchUserLoading,
  user: state.users.user,
  updateUserLoading: state.loading.updateUserLoading,
  deleteUserLoading: state.loading.deleteUserLoading,
  sendUserInvitationLoading: state.loading.sendUserInvitationLoading,
  authUser: state.auth,
  userInvitation: state.users.userInvitation,
  deleteUserInvitationLoading: state.loading.deleteUserInvitationLoading,
  updateUserInvitationLoading: state.loading.updateUserInvitationLoading
});

const mapActionsToProps = {
  fetchProgrammesAction: fetchProgrammes,
  fetchUserAction: fetchUser,
  updateUserAction: updateUser,
  deleteUserAction: deleteUser,
  requestUserPasswordResetAction: requestUserPasswordReset,
  sendUserInvitationAction: sendUserInvitation,
  deleteUserInvitationDetailsByTokenAction: deleteUserInvitationDetailsByToken,
  updateUserInvitationAction: updateUserInvitation
};

export default connect(mapStateToProps, mapActionsToProps)(UserAddEdit);
