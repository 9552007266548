const updateAnswersHeadersArray = ({
  questionText,
  dynamicHeadersArray,
  order,
  groupOrder
}) => {
  const questionKey = { text: questionText, order, groupOrder };
  if (!dynamicHeadersArray.some(header => header.text === questionText)) {
    dynamicHeadersArray.push(questionKey);
    dynamicHeadersArray.sort((a, b) => {
      if (a.groupOrder === b.groupOrder) {
        return a.order - b.order;
      }
      return a.groupOrder - b.groupOrder;
    });
  }
};

const addAnswerToParticipant = ({ participantObj, questionText, answer }) => {
  let _answer = answer;
  if (Array.isArray(_answer)) {
    _answer = _answer.join(', ');
  } else if (typeof _answer === 'object') {
    let str = '';
    Object.entries(_answer).forEach(([key, value]) => {
      str += `${key}: ${value}，`;
    });
    _answer = str;
  }
  const questionKey = questionText;

  if (typeof _answer === 'string') {
    return (participantObj[questionKey] = _answer?.replace(
      /,/g,
      '，'
    ))?.replace(/"/g, '”');
  }
  return (participantObj[questionKey] = _answer);
};

const transformer = ({ participant, headers }) => {
  const participantObj = { ...participant };

  if (headers.fixedHeaders.length === 0) {
    const keys = Object.keys(participantObj).filter(
      key => key !== 'demographics'
    );
    headers.fixedHeaders = [...new Set([...headers.fixedHeaders, ...keys])];
  } else {
    Object.keys(participantObj).forEach(key => {
      if (!headers.fixedHeaders.includes(key) && key !== 'demographics') {
        headers.fixedHeaders = [...new Set([...headers.fixedHeaders, key])];
      }
    });
  }

  if (participant.demographics && participant.demographics.length > 0) {
    participant.demographics.forEach(demographic => {
      const { question, answer, order, groupOrder } = demographic;
      updateAnswersHeadersArray({
        questionText: question,
        dynamicHeadersArray: headers.dynamicHeadersArray,
        order,
        groupOrder
      });
      addAnswerToParticipant({
        participantObj,
        questionText: question,
        answer
      });
    });
  }

  delete participantObj.demographics;
  return participantObj;
};

const getCsvDataAndHeadersForExportDemographics = data => {
  const headers = {
    fixedHeaders: [],
    dynamicHeadersArray: []
  };

  const csvDataWithDemographics = data.map(participant =>
    transformer({
      participant,
      headers
    })
  );

  const dynamicHeaders = headers.dynamicHeadersArray.map(header => header.text);

  return {
    csvData: csvDataWithDemographics,
    csvHeaders: [...headers.fixedHeaders, ...dynamicHeaders]
  };
};

export default getCsvDataAndHeadersForExportDemographics;
