import axios from 'axios';
import {
  LOADING_TRUE,
  LOADING_FALSE,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  CLEAR_SEARCH
} from '../constants/actionTypes';

export const setSearchQuery = ({
  query,
  category,
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: LOADING_TRUE,
      payload: 'searchLoading'
    });

    const res = await axios.get(`/api/${category}`, {
      params: { isSearch: true, query, filters, forPublic: true }
    });
    const { data } = res;

    dispatch({
      type: SEARCH_SUCCESS,
      payload: { category, data, error: null }
    });

    return data;
  } catch (error) {
    dispatch({
      type: SEARCH_FAIL,
      payload: {
        category,
        data: [],
        error: error?.response?.data?.error || error
      }
    });
  } finally {
    dispatch({
      type: LOADING_FALSE,
      payload: 'searchLoading'
    });
  }
};

export const clearSearch = category => dispatch => {
  dispatch({
    type: CLEAR_SEARCH,
    payload: { category }
  });
};
