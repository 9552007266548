import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import { Col, Row } from '../../common/Grid';
import Spin from '../../common/Spin';
import * as T from '../../common/Typography';

import { fetchProgrammes } from '../../../actions/programmesActions';
import { MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW } from '../../../constants/navigationRoutes';
import USER_TYPES from '../../../constants/userTypes';
import Layout from '../../Layouts';
import AccountPageHeader from '../../common/AccountPageHeader';
import { Dropdown } from '../../common/Inputs';

const ProgrammeSelection = ({
  programmes,
  loading,
  fetchProgrammes,
  role,
  programmesCanDeliver
}) => {
  const history = useHistory();

  const [programme, setProgramme] = useState(null);

  const handleProgrammeSelection = useCallback(
    slug => {
      setProgramme(slug);
      history.push(MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW.replace(':slug', slug));
    },
    [history]
  );

  useEffect(() => {
    fetchProgrammes({ forAdmin: true });
  }, [fetchProgrammes]);

  if (!programmes || loading) {
    return <Spin />;
  }

  if (
    role === USER_TYPES.programmeManager &&
    programmesCanDeliver?.length === 1 &&
    !!programmes?.length
  ) {
    const programmeSlug = programmes.find(
      programme => programme._id === programmesCanDeliver[0]
    ).slug;

    history.push(
      MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW.replace(':slug', programmeSlug)
    );
  }

  const sortedProgrammes = programmes.sort((a, b) =>
    a.titleShort.localeCompare(b.titleShort)
  );

  return (
    <Layout>
      <Row mb={7}>
        <AccountPageHeader title="Programmes" />
        <Col w={[4, 8, 8]}>
          <T.P>Please select a programme you wish to manage</T.P>
        </Col>
        <Col w={[4, 8, 8]}>
          <Dropdown
            selected={programme}
            placeholder="Select..."
            required
            options={sortedProgrammes
              .filter(programme => {
                if (role === USER_TYPES.programmeManager) {
                  return programmesCanDeliver?.includes(programme._id);
                }
                return true;
              })
              .map(programme => ({
                label: programme.titleShort,
                value: programme.slug
              }))}
            name="programme"
            handleChange={handleProgrammeSelection}
          />
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  programmes: state.programmes.programmes,
  loading: state.loading.fetchProgrammesLoading,
  programmesCanDeliver: state.auth.programmesCanDeliver,
  role: state.auth.role
});

const mapActionsToProps = {
  fetchProgrammes
};

export default connect(mapStateToProps, mapActionsToProps)(ProgrammeSelection);
